import routes from '../routes';

const canSeeRoute = (navOption, roles, userIsAdmin) => {
  const { allowedRoles, isAdmin, hide } = navOption;
  if (hide) {
    return false;
  }
  if (allowedRoles) {
    return roles.map((({ roleName }) => allowedRoles.includes(roleName)))
      .includes(true);
  }
  if (isAdmin && !userIsAdmin) {
    return false;
  }
  return null;
};

export const checkRouteAccess = (pathname, foundUser, roles, userIsAdmin, history) => {
  if (pathname) {
    const foundRoute = routes.find((possibleRoute) => pathname === possibleRoute.path);
    if (!foundUser) {
      history.push('/login');
    }
    if (foundRoute && !canSeeRoute(foundRoute, roles, userIsAdmin)) {
      history.push('/admin/dashboard');
    }
  }
};
