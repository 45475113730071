const { origin } = window.location;

let BASEURL = 'https://api.psylaris.nl/api';
let BASE_DEVICE_STREAM_URL =
  'https://stream-device-server-dot-turnkey-setup-246812.ew.r.appspot.com';
let EMDR_REMOTE_PATH = 'https://emdr-remote.psylaris.nl';
let MEDICAL_HYPNOSIS_PATH;

const isLocalHost = origin.includes('localhost:');
if (isLocalHost) {
  // BASEURL = 'http://localhost:8081/api';
  BASEURL = 'https://api-acc-dot-turnkey-setup-246812.appspot.com/api';
  EMDR_REMOTE_PATH = 'https://acc-emdr-remote.webtric.be';
  // MEDICAL_HYPNOSIS_PATH = 'http://localhost:3000/product/medical_hypnosis';
  MEDICAL_HYPNOSIS_PATH =
    'https://stream-device-server-acc-dot-turnkey-setup-246812.ew.r.appspot.com/product/medical_hypnosis';
  BASE_DEVICE_STREAM_URL =
    'https://stream-device-server-acc-dot-turnkey-setup-246812.ew.r.appspot.com/';
}

const isAcceptance = origin.includes('web-demo-dot-turnkey-setup-246812');
if (isAcceptance) {
  BASEURL = 'https://api-acc-dot-turnkey-setup-246812.appspot.com/api';
  EMDR_REMOTE_PATH = 'https://acc-emdr-remote.webtric.be';
  MEDICAL_HYPNOSIS_PATH =
    'https://stream-device-server-acc-dot-turnkey-setup-246812.ew.r.appspot.com/product/medical_hypnosis';
  BASE_DEVICE_STREAM_URL =
    'https://stream-device-server-acc-dot-turnkey-setup-246812.ew.r.appspot.com/';
}

export const ANON_CLIENT_IDENTIFIER = '[QuickstartSessions] ';
export const EMDR_REMOTE_URL = EMDR_REMOTE_PATH;
export const MEDICAL_HYPNOSIS_URL = MEDICAL_HYPNOSIS_PATH;
export const CLIENT_PAGE = 'clientpage';
export const PRACTITIONER_PAGE = 'practitionerpage';
export const BASE_PRACTITIONER_URL = `${EMDR_REMOTE_URL}/${PRACTITIONER_PAGE}`;
export const THERAPY_ROOM = 'Therapy room';
export const REMOTE_THERAPY = 'Remote session';
export const MAX_TRIES = 120000;
export const INTERVAL_TIME_IN_MS = 3000;
export const EMAIL_REGEX =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const DEVICE_STREAM_URL = BASE_DEVICE_STREAM_URL;
export default BASEURL;
