import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';


import { withTranslation } from 'react-i18next';
import { connect, Provider } from 'react-redux';

import { userActions } from './store/actions/user.actions';
import { store } from './store/store';

import { history } from './utils/history';
import './utils/icon-library';

import Dashboard from './views/Dashboard';
import LoginPage from './views/LoginPage';
import PasswordReset from './views/PasswordReset';

import { useValidateAccessToken } from './hooks/useValidateAccessToken';

import Loader from './components/Loader';
import PaymentUpdate from './components/PaymentUpdate/PaymentUpdate';

import './App.scss';

toast.configure();

const App = function ({ logout, i18n }) {
  const { isValidatingToken } = useValidateAccessToken(logout);

  if (isValidatingToken || !i18n.isInitialized) {
    return (
      <div className='main-container-center'>
        <Loader />
      </div>
    );
  }

  return (
    <Provider store={store}>
        <ToastContainer
          position='bottom-right'
          autoClose={3000}
          hideProgressBar={false}
          transition={Slide}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Router history={history}>
          <Switch>
            <Route
              key='payment-success'
              path='/payments/success'
              component={PaymentUpdate}
            />
            {/* <Route key="payment-success" path="/payments/success" component={PaymentUpdate} /> */}
            <Route key='admin-app' path='/admin' component={Dashboard} />
            <Route
              exact
              key='admin-login'
              path='/login'
              component={LoginPage}
            />
            <Route
              exact
              key='password-reset'
              path='/password-reset'
              component={PasswordReset}
            />
            <Redirect from='/' to='/login' />
          </Switch>
        </Router>
    </Provider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  logout: userActions.logout,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(App),
);
