import { getI18n } from "react-i18next";
import { userConstants } from "../constants/user.constants";
import { userService } from "../../services/login";
import { alertActions } from "./alert.actions";
import { history } from "../../utils/history";

export const userActions = {
  login,
  logout,
  findUserById,
  changePassword,
  resetPassword,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username, password }));
    return userService.login(username, password).then(
      (user) => {
        dispatch(success(user.data));
        history.push("/admin/dashboard");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t("errors.login.failed")));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout(queryParam = null) {
  return (dispatch) => {
    // if (!window.confirm('Are you sure you wish to logout?')) {
    //   return;
    // }
    userService.logout();
    let searchPath = "/login";
    if (queryParam) {
      searchPath = { pathname: "/login", search: queryParam };
    }
    history.push(searchPath);
    dispatch({ type: userConstants.LOGOUT });
  };
}

function changePassword(data) {
  return (dispatch) => {
    dispatch({ type: userConstants.CHANGE_PASSWORD });
    return userService.changePassword(data);
  };
}

function resetPassword(data, token) {
  return (dispatch) => {
    dispatch({ type: userConstants.RESET_PASSWORD });
    return userService.resetPassword(data, token);
  };
}

function findUserById(id) {
  return (dispatch) => {
    dispatch(request({ id }));

    return userService.findUserById(id).then(
      (user) =>
        userService.getRole(id).then(
          (result) => {
            const newUser = user.data;
            newUser.roleName = result.data[0].roleName;
            dispatch(success(newUser));
          },
          (error) => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        ),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.GET_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.GET_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.GET_FAILURE, error };
  }
}
