/* eslint-disable import/no-cycle */
import React from 'react';
import SessionsTable from './components/Sessions/SessionsTable';
import Organizations from './components/Organizations/Organizations';
import TeamsTable from './components/Teams/TeamsTable';
import TreatmentsTable from './components/Treatments/TreatmentsTable';
import PractitionersTable from './components/Practitioners/PractitionersTable';
import DevicesTable from './components/Devices/DevicesTable';
import ClientsTable from './components/Clients/ClientsTable';
import DashboardPage from './views/DashboardPage';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import CreateTreatmentPage from './views/CreateTreatmentPage';
import TeamDetail from './views/TeamDetail';
import SessionDetail from './views/SessionDetail';
import PractitionerDetailPage from './views/PractitionerDetailPage';
import DeviceDetailPage from './views/DeviceDetailPage';
import AccountManagementPage from './views/AccountManagementPage';
import BillingPage from './views/BillingPage';
import AdminPage from './views/AdminPage';

// Create treatment lazy component
const CreateTreatmentWizard = React.lazy(() =>
  import('./components/Treatments/CreateTreatmentWizard/CreateTreatmentWizard'),
);

// It's important to keep this order, the children need to come before the parent routes.
const routes = [
  {
    path: '/admin/dashboard',
    label: 'routes.dashboard',
    icon: 'home',
    component: DashboardPage,
    layout: '/admin/admin',
    isAdmin: false,
    allowedRoles: [
      'super-admin',
      'organization-manager',
      'team-manager',
      'device-manager',
      'client-reviewer',
      'client-manager',
      'session-reviewer',
      'treatment-configuration-manager',
      'treatment-manager',
      'client-role',
      'device-role',
      'domain-administrator',
    ],
  },
  {
    path: '/admin/organizations',
    label: 'routes.organizations',
    icon: 'organizations',
    component: Organizations,
    containerName: 'organizations-container',
    layout: '/admin/admin',
    isAdmin: true,
    allowedRoles: ['super-admin', 'logistics-admin'],
  },
  {
    path: '/admin/teams/:id',
    component: TeamDetail,
    hide: true,
    exact: true,
  },
  {
    path: '/admin/teams',
    label: 'routes.teams',
    icon: 'teams',
    component: TeamsTable,
    layout: '/admin/admin',
    isAdmin: true,
    allowedRoles: [
      'super-admin',
      'organization-manager',
      'team-manager',
      'logistics-admin',
    ],
  },
  {
    path: '/admin/practitioners/:id',
    component: PractitionerDetailPage,
    hide: true,
    exact: true,
  },
  {
    path: '/admin/practitioners',
    label: 'routes.practitioners',
    icon: 'practitioners',
    component: PractitionersTable,
    layout: '/admin/admin',
    isAdmin: true,
    allowedRoles: ['super-admin', 'organization-manager', 'logistics-admin'],
  },
  {
    path: '/admin/remote-view',
    component: DeviceDetailPage,
    hide: true,
    exact: true,
    allowedRoles: ['super-admin', 'organization-manager', 'logistics-admin'],
  },
  {
    path: '/admin/devices/:id',
    component: DeviceDetailPage,
    hide: true,
    exact: true,
  },
  {
    path: '/admin/devices',
    label: 'routes.devices',
    icon: 'devices',
    component: DevicesTable,
    layout: '/admin/admin',
    allowedRoles: [
      'super-admin',
      'organization-manager',
      'team-manager',
      'device-manager',
      'domain-administrator',
      'device-admin',
    ],
  },
  {
    path: '/admin/clients',
    label: 'routes.clients',
    icon: 'clients',
    component: ClientsTable,
    layout: '/admin/admin',
    allowedRoles: ['team-manager', 'client-reviewer', 'client-manager'],
  },
  // TODO: uncomment for treatment wizard
  // {
  //   path: '/admin/treatments/create-wizard',
  //   component: CreateTreatmentWizard,
  //   hide: true,
  //   exact: true,
  // },
  // TODO: remove when treatment wizard be published
  {
    path: '/admin/treatments/create',
    component: CreateTreatmentPage,
    hide: true,
    exact: true,
  },
  {
    path: '/admin/treatments',
    label: 'routes.treatments',
    icon: 'treatment',
    component: TreatmentsTable,
    layout: '/admin/admin',
    allowedRoles: [
      'organization-manager',
      'treatment-configuration-manager',
      'treatment-manager',
    ],
  },
  {
    path: '/admin/sessions/:id',
    component: SessionDetail,
    hide: true,
    exact: true,
  },
  {
    path: '/admin/sessions',
    label: 'routes.sessions',
    icon: 'session',
    component: SessionsTable,
    containerName: 'main-container',
    isOpenRoute: true,
    layout: '/admin/admin',
    allowedRoles: [
      'super-admin',
      'organization-manager',
      'client-reviewer',
      'client-manager',
      'session-reviewer',
      'client-role',
      'device-role',
      'domain-administrator',
    ],
  },
  {
    path: '/admin/billing',
    label: 'routes.billing',
    icon: 'billing-card',
    component: BillingPage,
    containerName: 'main-container',
    isOpenRoute: true,
    layout: '/admin/admin',
    isBillingRoute: true,
    allowedRoles: [
      'super-admin',
      'organization-manager',
      'organization-manager',
      'client-reviewer',
      'client-manager',
      'session-reviewer',
    ],
  },
  {
    path: '/admin/account-management',
    component: AccountManagementPage,
    isAdmin: false,
    hide: true,
  },
  {
    path: '/admin/admin-configuration',
    label: 'routes.admin-configuration',
    icon: 'admin-settings',
    component: AdminPage,
    containerName: 'main-container',
    isOpenRoute: true,
    layout: '/admin/admin',
    isAdmin: true,
    allowedRoles: ['super-admin'],
  },
];

export default routes;
